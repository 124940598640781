<template>
  <div class="position-relative side-alert">
    <div class="position-absolute overflow-auto w-100 h-100 bg-white">

    <el-drawer title="I am the title"  direction="rtl" size="400px">
      <div class="pt-3 pl-5 pr-5 pb-5">
        <el-form :model="form" ref="form">
          <el-form-item label="Title" prop="name">
            <el-input type="text" v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="Detail" prop="email">
            <el-input type="textarea" v-model="form.email" :rows="5"></el-input>
          </el-form-item>          
          <el-button type="primary" @click="submitForm('form')">Save</el-button>
        </el-form>
      </div>
    </el-drawer>

    </div>

    
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "today",
      state: 0,
      form: {
        id: null,
        name: null,
        email: null,
        phone: null,
        discriminator: null,
        office: null
      }
    };
  }
};
</script>

<style lang="scss">
.todo-state {
  top: 15px;
  right: 5px;
}
.new-task {
  bottom: 20px;
  margin: auto;
}
.btn-big {
  line-height: 1.5;
  letter-spacing: 0.05rem;
  padding: 10px 40px;
}
</style>